import { useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ConfirmPage from './components/confirmPage';
import RegistrationPage from './components/registrationPage';

function App() {
  const [currentLanguage, setLanguage] = useState<string>('en');

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <RegistrationPage
              setLanguage={setLanguage}
              currentLanguage={currentLanguage}
            />
          }
        />
        <Route
          path="/confirm"
          element={
            <ConfirmPage
              setLanguage={setLanguage}
              currentLanguage={currentLanguage}
            />
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
