const NavBarConfirm = () => {
  return (
    <>
      <nav
        className="uk-navbar-container uk-navbar-transparent"
        uk-navbar="true"
      >
        <div
          className="uk-navbar-left uk-margin-small-left-"
          style={{ marginLeft: '20px' }}
        >
          <a className="uk-navbar-item uk-logo" href="/">
            <img src="img/apollo.png" alt="autosen Cloud" />
          </a>
        </div>

        <div id="sidebar" uk-offcanvas="mode: push;">
          <div
            className="
                  uk-offcanvas-bar uk-flex uk-flex-column uk-off-canvas
                  as-background-default
                "
          >
            <button
              className="uk-offcanvas-close"
              type="button"
              uk-close="true"
            ></button>

            <ul
              className="
                    uk-nav uk-nav-primary uk-nav-center uk-margin-auto-vertical
                  "
            >
              <li className="uk-margin-medium-bottom">
                <a
                  href="#register"
                  className="
                        uk-button uk-button-default
                        as-button-primary as-button-outline
                        uk-border-rounded
                      "
                  uk-scroll="true"
                >
                  Jetzt aktivieren
                </a>
              </li>
              <li>
                <div className="uk-flex uk-flex-center">
                  <a
                    href="{{ path_for('index', { 'lang': 'de' }) }}"
                    className="uk-margin-small-right uk-transition-toggle"
                  >
                    <img
                      className="uk-transition-scale-up uk-transition-opaque"
                      src="img/lang/de/flag.png"
                      alt="Deutsch"
                    />
                  </a>
                  <a
                    href="{{ path_for('index', { 'lang': 'en' }) }}"
                    className="uk-margin-small-right uk-transition-toggle"
                  >
                    <img
                      className="uk-transition-scale-up uk-transition-opaque"
                      src="img/lang/en/flag.png"
                      alt="English"
                    />
                  </a>
                  <a
                    href="{{ path_for('index', { 'lang': 'fr' }) }}"
                    className="uk-margin-small-right uk-transition-toggle"
                  >
                    <img
                      className="uk-transition-scale-up uk-transition-opaque"
                      src="img/lang/fr/flag.png"
                      alt="Français"
                    />
                  </a>
                  <a
                    href="{{ path_for('index', { 'lang': 'it' }) }}"
                    className="uk-transition-toggle"
                  >
                    <img
                      className="uk-transition-scale-up uk-transition-opaque"
                      src="img/lang/it/flag.png"
                      alt="Italiano"
                    />
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavBarConfirm;
