import React, { useState } from 'react';
import '../App.css';
import { useTranslation } from 'react-i18next';
import NavBar from './navbar';
import StarterForm from './starterForm';
import ErrorModal from './errorModal';
import SuccessModalApollo from './successModalApollo';

import ImprintModal from './imprintModal';

interface IRegistrationPage {
  currentLanguage: string;
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
}

function RegistrationPage({ currentLanguage, setLanguage }: IRegistrationPage) {
  const { t } = useTranslation();
  const [imprintIsOpen, setImprintOpen] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [showSuccessModalApollo, setShowSuccessModalApollo] =
    useState<boolean>(false);

  //Error modal
  const [errorModalIsOpen, setErrorModalOpen] = useState<boolean>(false);
  const errorMessageHeading = t('errors.heading');
  const [errorMessageBody, setErrorMessageBody] = useState<string>('');

  //State starter form
  const [apolloId, setApolloId] = useState<string>('');
  const [url, setUrl] = useState<string>('');

  const handleFormSubmit = async () => {
    setLoading(true);
    try {
      let res = await fetch('/api/v1/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          language: currentLanguage,
          apolloId: apolloId,
          cloudEndpoint: url
        })
      });

      setLoading(false);
      if (!res.ok) {
        if (res.status === 400) {
          setErrorMessageBody(t('errors.form'));
          setErrorModalOpen(true);
        } else if (res.status === 304) {
          setErrorMessageBody(t('errors.url_already_in_use'));
          setErrorModalOpen(true);
        } else if (res.status === 500) {
          setErrorMessageBody(t('errors.500'));
          setErrorModalOpen(true);
        } else if (res.status === 404) {
          setErrorMessageBody(t('errors.apollo_id'));
          setErrorModalOpen(true);
        } else {
          setErrorMessageBody(t('errors.general'));
          setErrorModalOpen(true);
        }
      } else {
        setShowSuccessModalApollo(true);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setErrorMessageBody(t('errors.general'));
      setErrorModalOpen(true);
    }
  };

  return (
    <>
      <div
        className="uk-offcanvas-content App"
        style={{ backgroundColor: '#111a23', height: '100vh' }}
      >
        <div className="uk-section uk-padding-remove-vertical">
          <NavBar setLanguage={setLanguage} currentLanguage={currentLanguage} />
        </div>

        <div id="register" className="uk-section uk-padding-remove-vertical">
          <div
            className="
            uk-container
            as-container-medium
            uk-padding-remove
            as-background-primary
            uk-position-relative
          "
            uk-img="true"
            style={{ marginTop: '80px' }}
          >
            <div
              className="
              uk-container uk-container-small uk-margin-small-top uk-text-center
              as-text-dark
              uk-border-rounded uk-background-default uk-position-relative
            "
              style={{ zIndex: '1' }}
            >
              <StarterForm
                setUrl={setUrl}
                url={url}
                currentLanguage={currentLanguage}
                handleFormSubmit={handleFormSubmit}
                loading={loading}
                apolloId={apolloId}
                setApolloId={setApolloId}
              />
            </div>
          </div>
        </div>

        <div
          className="uk-section uk-padding-remove-vertical"
          style={{
            position: 'absolute',
            bottom: '0',
            margin: 'auto',
            width: '100%',
            overflow: 'hidden'
          }}
        >
          <div
            className="
            uk-container
            as-container-medium
            uk-padding-remove
            as-background-primary
          "
          >
            <div className="uk-padding uk-flex uk-flex-center">
              <button
                style={{ background: 'none', border: 'none' }}
                className="as-link as-text-default uk-margin-small-right"
                onClick={() => setImprintOpen(true)}
              >
                {t('footer.site_notice.label')}
              </button>
              <a
                href={t('footer.privacy.customurl')}
                data-title={t('footer.privacy.title')}
                className="as-link as-text-default uk-margin-small-right"
                target="_blank"
                rel="noreferrer"
              >
                {t('footer.privacy.label')}
              </a>
              <a
                href={t('footer.terms_and_conditions.customurl')}
                data-title={t('footer.terms_and_conditions')}
                className="as-link as-text-default uk-margin-small-right"
                target="_blank"
                rel="noreferrer"
              >
                {t('footer.terms_and_conditions.label')}
              </a>
              <a
                href={t('footer.ats_eula.customurl')}
                data-title={t('footer.ats_eula')}
                className="as-link as-text-default uk-margin-small-right"
                target="_blank"
                rel="noreferrer"
              >
                {t('footer.ats_eula.label')}
              </a>
              <a
                href={t('footer.ats_foss_apollo.customurl')}
                data-title={t('footer.ats_foss_apollo')}
                className="as-link as-text-default uk-margin-small-right"
                target="_blank"
                rel="noreferrer"
              >
                {t('footer.ats_foss_apollo.label')}
              </a>
              <a
                href={t('footer.ats_foss_iokey.customurl')}
                data-title={t('footer.ats_foss_iokey')}
                className="as-link as-text-default"
                target="_blank"
                rel="noreferrer"
              >
                {t('footer.ats_foss_iokey.label')}
              </a>
            </div>
          </div>
        </div>
      </div>

      <ImprintModal
        imprintIsOpen={imprintIsOpen}
        setImprintOpen={setImprintOpen}
        t={t}
      />

      <ErrorModal
        errorModalIsOpen={errorModalIsOpen}
        setErrorModalOpen={setErrorModalOpen}
        t={t}
        errorMessageBody={errorMessageBody}
        errorMessageHeading={errorMessageHeading}
      />

      <SuccessModalApollo
        t={t}
        showSuccessModalApollo={showSuccessModalApollo}
        setShowSuccessModalApollo={setShowSuccessModalApollo}
      />
    </>
  );
}

export default RegistrationPage;
