import React from 'react';

interface IConfirmLogic {
  loading: boolean;
  success: boolean;
  t: any;
}

const ConfirmLogic: React.FC<IConfirmLogic> = ({ loading, success, t }) => {
  console.log(success);

  if (loading) {
    return (
      <div className="uk-padding-large uk-margin-auto">
        <div className="spinner-container uk-margin-auto">
          <div className="loading-spinner-large"></div>
        </div>
      </div>
    );
  } else {
    if (success) {
      return (
        <div className="uk-width-1-1">
          <h3>
            <b>{t('confirm.heading')}</b>
          </h3>
          <svg
            className="uk-margin-large-top"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
            width="144px"
            height="144px"
          >
            <path
              fill="#78BE20"
              d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
            />
            <path
              fill="#ffffff"
              d="M34.602,14.602L21,28.199l-5.602-5.598l-2.797,2.797L21,33.801l16.398-16.402L34.602,14.602z"
            />
          </svg>
        </div>
      );
    } else {
      return (
        <div className="uk-width-1-1">
          <h3>
            <b>{t('errors.heading')}</b>
          </h3>
          <div className="uk-margin-large-top">
            <img src={'img/error.png'} alt="error" />
          </div>
          <div className="uk-margin-top">
            <p>{t('confirm.errorText')}</p>
          </div>
        </div>
      );
    }
  }
};

export default ConfirmLogic;
