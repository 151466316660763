import i18next from 'i18next';

export const validateUrl = (
  url: string
): { validated: boolean; message: string } => {
  const regex =
    /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

  if (!regex.test(url)) {
    return { validated: false, message: i18next.t('validation.url') };
  }

  return { validated: true, message: '' };
};

export const validateCustomerNr = (
  customerNr: string
): { validated: boolean; message: string } => {
  const regex = /^[0-9]*$/;

  if (!regex.test(customerNr)) {
    return { validated: false, message: i18next.t('validation.customerNr') };
  } else if (customerNr.length < 7) {
    return {
      validated: false,
      message: i18next.t('validation.customerNrLength')
    };
  }
  return { validated: true, message: '' };
};

export const validateApolloId = (
  apolloId: string
): { validated: boolean; message: string } => {
  const regex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;

  if (!regex.test(apolloId)) {
    return { validated: false, message: i18next.t('validation.apolloId') };
  }
  return { validated: true, message: '' };
};

export const requiredField = () => {
  return { validated: false, message: i18next.t('validation.required') };
};
