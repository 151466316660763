import React from 'react';
import { useState } from 'react';
import { requiredField } from '../utils/validation';

interface IFormInput {
  validationFct: (any: any) => {
    validated: boolean;
    message: string;
  };
  rest?: any;
  input: any;
  props?: any;
  id?: string;
  name?: string;
  type?: string;
  className?: string;
  placeholder?: string;
  autoComplete?: string;
  required?: boolean;
  pattern?: string;
  maxLength?: any;
  onChange?: any;
  isValidated: boolean;
  setValidated: React.Dispatch<React.SetStateAction<boolean>>;
  children?: any;
  minLength?: any;
  style?: any;
}

const FormInput: React.FC<IFormInput> & React.HTMLAttributes<any> = ({
  validationFct,
  input,
  id,
  name,
  type,
  className,
  placeholder,
  autoComplete,
  required,
  pattern,
  maxLength,
  onChange,
  isValidated,
  setValidated,
  minLength,
  style,
  ...props
}) => {
  const [empty, setEmpty] = useState<boolean>(false);

  return (
    <div>
      <input
        onBlur={(e) => {
          if (input) {
            setEmpty(false);
            setValidated(validationFct(input).validated);
          } else if (input === '' || !input) {
            setEmpty(true);
          }
        }}
        id={id}
        name={name}
        type={type}
        className={className}
        placeholder={placeholder}
        autoComplete={autoComplete}
        required={required}
        maxLength={maxLength}
        pattern={pattern}
        onChange={onChange}
        minLength={minLength}
        style={style}
      />
      <div>
        {isValidated ? (
          <></>
        ) : (
          <label className="uk-text-danger" style={{ fontSize: '80%' }}>
            {validationFct(input).message}
          </label>
        )}
      </div>
      <div>
        {empty ? (
          <label className="uk-text-danger" style={{ fontSize: '80%' }}>
            {requiredField().message}
          </label>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default FormInput;
