import React, { useEffect, useState } from 'react';
import '../App.css';
import { useTranslation } from 'react-i18next';
import NavBarConfirm from './navbarConfirm';
import ConfirmLogic from './confirmLogic';
import ImprintModal from './imprintModal';
import { useSearchParams } from 'react-router-dom';

interface IConfirmPage {
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
  currentLanguage: string;
}

function ConfirmPage({ setLanguage }: IConfirmPage) {
  const { t, i18n } = useTranslation();
  const [imprintIsOpen, setImprintOpen] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const handlePageLoad = async () => {
      const language = searchParams.get('language');

      if (language) {
        setLanguage(language);
        i18n.changeLanguage(language);
      }

      const token = searchParams.get('token');

      if (token) {
        try {
          setLoading(true);
          let res = await fetch('/api/v1/confirm', {
            method: 'POST',
            headers: new Headers({
              Authorization: 'Bearer ' + token,
              'Content-Type': 'application/json'
            })
          });
          setLoading(false);

          if (!res.ok) {
            if (
              res.status === 401 ||
              res.status === 500 ||
              res.status === 404
            ) {
              setSuccess(false);
            }
          } else {
            setSuccess(true);
          }
        } catch (error) {
          setLoading(false);
          setSuccess(false);
          console.log(error);
        }
      }
    };
    handlePageLoad();
  }, [i18n, searchParams, setLanguage]);

  return (
    <>
      <div
        className="uk-offcanvas-content App"
        style={{ backgroundColor: '#111a23', height: '100vh' }}
      >
        <div className="uk-section uk-padding-remove-vertical">
          <NavBarConfirm />
        </div>

        <div id="register" className="uk-section uk-padding-remove-vertical">
          <div
            className="
            uk-container
            as-container-medium
            uk-padding-remove
            as-background-primary
            uk-position-relative
          "
            uk-img="true"
            style={{ marginTop: '80px' }}
          >
            <div
              className="
              uk-container uk-container-small uk-margin-small-top uk-text-center
              as-text-dark
              uk-border-rounded uk-background-default uk-position-relative
            "
              style={{ zIndex: '1' }}
            >
              <div
                id="registration"
                className="uk-padding-large uk-grid-small"
                uk-grid="true"
              >
                <ConfirmLogic t={t} success={success} loading={loading} />

                <div className="uk-width-1-1 uk-margin-large-top">
                  <a
                    href="/"
                    type="submit"
                    className="
                      uk-button uk-button-primary
                      as-button-primary
                      uk-button-large uk-border-rounded
                      as-hide-if-loading
                    "
                    onClick={(e) => console.log('test')}
                  >
                    {<p>{t('confirm.button')}</p>}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="uk-section uk-padding-remove-vertical"
          style={{
            position: 'absolute',
            bottom: '0',
            margin: 'auto',
            width: '100%',
            overflow: 'hidden'
          }}
        >
          <div
            className="
            uk-container
            as-container-medium
            uk-padding-remove
            as-background-primary
          "
          >
            <div className="uk-padding uk-flex uk-flex-center">
              <button
                style={{ background: 'none', border: 'none' }}
                className="as-link as-text-default uk-margin-small-right"
                onClick={() => setImprintOpen(true)}
              >
                {t('footer.site_notice.label')}
              </button>
              <a
                href={t('footer.privacy.customurl')}
                data-title={t('footer.privacy.title')}
                className="as-link as-text-default uk-margin-small-right"
                target="_blank"
                rel="noreferrer"
              >
                {t('footer.privacy.label')}
              </a>
              <a
                href={t('footer.terms_and_conditions.customurl')}
                data-title={t('footer.terms_and_conditions')}
                className="as-link as-text-default uk-margin-small-right"
                target="_blank"
                rel="noreferrer"
              >
                {t('footer.terms_and_conditions.label')}
              </a>
              <a
                href={t('footer.ats_eula.customurl')}
                data-title={t('footer.ats_eula')}
                className="as-link as-text-default uk-margin-small-right"
                target="_blank"
                rel="noreferrer"
              >
                {t('footer.ats_eula.label')}
              </a>
              <a
                href={t('footer.ats_foss_apollo.customurl')}
                data-title={t('footer.ats_foss_apollo')}
                className="as-link as-text-default uk-margin-small-right"
                target="_blank"
                rel="noreferrer"
              >
                {t('footer.ats_foss_apollo.label')}
              </a>
              <a
                href={t('footer.ats_foss_iokey.customurl')}
                data-title={t('footer.ats_foss_iokey')}
                className="as-link as-text-default"
                target="_blank"
                rel="noreferrer"
              >
                {t('footer.ats_foss_iokey.label')}
              </a>
            </div>
          </div>
        </div>
      </div>

      <ImprintModal
        imprintIsOpen={imprintIsOpen}
        setImprintOpen={setImprintOpen}
        t={t}
      />
    </>
  );
}

export default ConfirmPage;
