import React from 'react';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from './loadingSpinner';
import FormInput from './formInput';
import { useState } from 'react';
import { validateUrl, validateApolloId } from '../utils/validation';

interface IStarterForm {
  currentLanguage: string;
  apolloId: string;
  setApolloId: React.Dispatch<React.SetStateAction<string>>;
  handleFormSubmit: any;
  loading: boolean;
  url: string;
  setUrl: React.Dispatch<React.SetStateAction<string>>;
}

const StarterForm: React.FC<IStarterForm> = ({
  currentLanguage,
  handleFormSubmit,
  loading,
  apolloId,
  setApolloId,
  url,
  setUrl
}) => {
  //Form Validation State
  const [apolloIdValidated, setApolloIdValidated] = useState<boolean>(true);
  const [domainValidated, setDomainValidated] = useState<boolean>(true);
  const [starterFormValidated, setStarterFormValidated] =
    useState<boolean>(true);

  const { t } = useTranslation();

  const handleFormValidation = (e: any) => {
    let formValid = true;
    let urlValid = true;
    let apolloIdValid = true;

    e.preventDefault();
    if (url && apolloId) {
      formValid = true;
      setStarterFormValidated(true);

      //Check if inputs are valid
      urlValid = validateUrl(url).validated;
      setDomainValidated(urlValid);

      apolloIdValid = validateApolloId(apolloId).validated;
      setApolloIdValidated(apolloIdValid);
    } else {
      formValid = false;
      setStarterFormValidated(false);
    }

    if (!urlValid || !apolloIdValid) {
      formValid = false;
      setStarterFormValidated(false);
    } else {
      formValid = true;
      setStarterFormValidated(true);
    }

    if (formValid === true) {
      handleFormSubmit();
    }
  };

  return (
    <form
      id="registration"
      className="uk-padding-large uk-grid-small"
      uk-grid="true"
    >
      <div className="uk-width-1-1">
        <h3>
          <b>{t('form.title_cloud')}</b>
        </h3>
      </div>

      <div className="uk-width-1-1">
        <label htmlFor="form-imei">{t('apollo.subheading')}</label>
        <div className="uk-margin">
          <FormInput
            input={apolloId}
            isValidated={apolloIdValidated}
            setValidated={setApolloIdValidated}
            validationFct={validateApolloId}
            id="form-imei"
            name="imei"
            type="text"
            className="
                      uk-input
                      as-input
                      uk-form-large
                      uk-text-center
                      uk-text-small
                      uk-border-rounded
                      uk-background-muted
                    "
            placeholder={t('apollo.placeholder')}
            autoComplete="off"
            required
            minLength={36}
            maxLength={36}
            onChange={(e: any) => setApolloId(e.currentTarget.value)}
          />
        </div>
      </div>

      <div className="uk-width-1-1">
        <label htmlFor="form-customer-id">{t('form.url.label')}</label>
        <div className="uk-margin">
          <FormInput
            isValidated={domainValidated}
            setValidated={setDomainValidated}
            validationFct={validateUrl}
            input={url}
            id="form-domain"
            name="domain"
            type="text"
            className="
                    uk-input
                    as-input
                    uk-form-large
                    uk-text-center
                    uk-text-small
                    uk-border-rounded
                    uk-background-muted
                  "
            placeholder={t('form.url.placeholder')}
            autoComplete="off"
            required
            minLength={3}
            maxLength={50}
            onChange={(e: any) => setUrl(e.currentTarget.value)}
          />
        </div>
      </div>

      <div className="uk-width-1-1 uk-margin-medium-top">
        <div className="uk-center-text">
          <button
            type="submit"
            className="
                      uk-button uk-button-primary
                      as-button-primary
                      uk-button-large uk-border-rounded
                      as-hide-if-loading
                    "
            onClick={(e) => handleFormValidation(e)}
          >
            {loading ? <LoadingSpinner /> : <p>{t('apollo.button')}</p>}
          </button>
          {starterFormValidated ? (
            <div></div>
          ) : (
            <label className="uk-text-danger" style={{ fontSize: '80%' }}>
              {t('errors.general')}
            </label>
          )}
        </div>
      </div>
    </form>
  );
};

export default StarterForm;
